import 'moment/locale/es';
import { NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule, bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { DynamicHttpTableModule } from '@components/ng-dynamic-http-table';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular-ivy';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AuthInterceptor } from 'src/shared-components/ng-login/auth/auth.interceptor';
import { createTranslateLoader, formatLanguage } from 'src/utils/translate';

import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { AuthGuardAdmin } from './shared-components/ng-login/auth/auth-admin.guard';
import { AuthGuardClient } from './shared-components/ng-login/auth/auth-client.guard';
import { AuthGuardLogged } from './shared-components/ng-login/auth/auth-logged.guard';
import { AuthGuardOperator } from './shared-components/ng-login/auth/auth-operator.guard';
import { LoginModule } from './shared-components/ng-login/login.module';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://88f361b898234ebf0cfcecefb241ed8b@o4505226924064768.ingest.us.sentry.io/4507174642384897',
    environment: environment.name,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: environment.tracesSampleRate,
    replaysSessionSampleRate: environment.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      MatDialogModule,
      MatDividerModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatCheckboxModule,
      MatSelectModule,
      MatSidenavModule,
      MatListModule,
      MatExpansionModule,
      MatGridListModule,
      MatToolbarModule,
      MatMenuModule,
      MatStepperModule,
      MatCardModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTableModule,
      MatPaginatorModule,
      MatBadgeModule,
      MatChipsModule,
      MatSliderModule,
      MatMomentDateModule,
      MatTooltipModule,
      MatTabsModule,
      NgxMaterialTimepickerModule,
      NgxPaginationModule,
      MatRadioModule,
      MatProgressBarModule,
      BrowserModule,
      AppRoutingModule,
      LoginModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        },
        defaultLanguage: formatLanguage(navigator.language)
      }),
      DynamicHttpTableModule,
      FormsModule,
      GoogleMapsModule,
      NgxSkeletonLoaderModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory
      }),
      NgOptimizedImage,
      MatButtonToggleModule
    ),
    AuthGuardOperator,
    AuthGuardAdmin,
    AuthGuardLogged,
    AuthGuardClient,
    provideMomentDateAdapter(),
    { provide: MatSnackBarConfig, useClass: MatSnackBarConfig },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY'
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MM/YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MM/YYYY'
        }
      }
    },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: environment.showDialog }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    provideClientHydration()
  ]
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
