import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FooterComponent } from './shared-components/footer/footer.component';
import { SessionManagerService } from '../utils/session-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {
  title = 'rent-a-bus-front';

  /**
   * AppComponent constructor
   * @param platformId
   * @param sessionManager Used to manage different user sessions
   * at the same time. It is not necessary to use it, just
   * declare it in the constructor
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionManager: SessionManagerService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sessionManager.listenToStorageChanges();
    }
  }
}
